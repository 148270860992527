<template>
    <div id="register">
        <div class="register-box">
            <div class="register-main">
                <!-- 标题 -->
                <div class="register-main-title">注册你的账户</div>
<!--                <div class="register-main-tabs" v-if="shoujiStep !=3">-->
<!--                    <div class="register-main-tab" :class="{'active' : currentregistermode == 'shouji'}" @click="switchregistermode">手机注册</div>-->
<!--                    <div class="register-main-tab" :class="{'active' : currentregistermode == 'pingtai'}" @click="switchregistermode">平台注册</div>-->
<!--                </div>-->
                <!-- 手机号注册 -->
                <template v-if="currentregistermode == 'shouji'">
                    <!-- 手机号 -->
                    <div class="register-main-input" v-if="shoujiStep ==1 || shoujiStep ==2">
                        <i class="lowcode icon-shouji register-main-input-icon"></i>
                        <el-input v-model="phonenumber" placeholder="输入手机号码"></el-input>
                    </div>
                    <!-- 验证码 -->
                    <div class="register-main-input register-main-input1" v-if="shoujiStep ==2">
                        <i class="lowcode icon-kuaijiejian register-main-input-icon"></i>
                        <el-input v-model="verificationCode" placeholder="输入验证码"></el-input>
                        <span class="register-main-input-tip" :class="{'register-main-input-tip1' : verificationCodeText != '获取验证码' }" @click="getVerificationCode">{{verificationCodeText}}</span>
                    </div>
                    <!-- 下一步 -->
                    <div class="register-main-button"  v-if="shoujiStep !=3" :class="{disabled: (shoujiStep == 1 && phonenumber == '') || (shoujiStep == 2 && (phonenumber == '' || verificationCode == ''))}" @click="nextStepBtn">下一步</div>

                    <!-- 手机号注册第三步提示 -->
                    <div v-if="shoujiStep ==3" class="register-main-title3">注册后首次登录，请为 {{phonenumber}} 账号设置密码</div>
                    <!-- 手机号注册第三步设置密码 -->
                    <div class="register-main-input" v-if="shoujiStep ==3">
                        <i class="lowcode icon-kaisuo register-main-input-icon"></i>
                        <el-input  type="password" show-password v-model="password" placeholder="输入密码"></el-input>
                    </div>
                    <!-- 手机号注册第三步按钮 -->
                    <div class="register-main-buttons forbidCopy" v-if="shoujiStep ==3">
                        <div class="register-main-buttons-item1" @click="shoujiStep = 2">上一步</div>
                        <div class="register-main-buttons-item2">注册</div>
                    </div>
                </template>
                <!-- 平台注册 -->
                <template v-if="currentregistermode == 'pingtai'">
                    <!-- 账号 -->
                    <div class="register-main-input">
                        <i class="lowcode icon-shouji register-main-input-icon"></i>
                        <el-input v-model="username" placeholder="请输入账号"></el-input>
                    </div>
                    <!-- 密码 -->
                    <div class="register-main-input">
                        <i class="lowcode icon-kaisuo register-main-input-icon"></i>
                        <el-input autocomplete="new-password" type="password" show-password v-model="password" placeholder="请输入密码"></el-input>
                    </div>
                    <!-- 邮箱 -->
<!--                    <div class="register-main-input">-->
<!--                        <i class="lowcode icon-youjian register-main-input-icon"></i>-->
<!--                        <el-input v-model="email" placeholder="请输入邮箱"></el-input>-->
<!--                    </div>-->
                    <!-- 下一步 -->
                    <div class="register-main-button forbidCopy" :class="{disabled: username == '' || password == '' || email == ''}" @click="registerBtn">注册</div>
                </template>
                <!-- 分割线 -->
<!--                <div v-if="shoujiStep !=3" class="register-main-division">-->
<!--                    <span class="line"></span>-->
<!--                    <span class="tip">第三方注册</span>-->
<!--                    <span class="line"></span>-->
<!--                </div>-->
                <!-- 第三方登录 -->
<!--                <div v-if="shoujiStep !=3" class="register-main-wechat" @click="currentregistermode = 'wechat'">-->
<!--                    <i class="lowcode icon-wechat"></i>-->
<!--                    <span>使用微信注册</span>-->
<!--                </div>-->
                <div v-if="shoujiStep !=3" class="register-main-zhuce">
                    <span>已有账号? </span>
                    <span @click="gotoLogin">前去登录 </span>
                    <i class="lowcode icon-qianwang"></i>
                </div>
                <div v-if="shoujiStep ==3" class="register-main-zhuce">
                    <span>点击「注册」表示阅读并同意 </span>
                    <span>服务条款 </span>
                </div>
            </div>
        </div>
        <!-- 轮播图 -->
        <div class="register-banner">
            <!-- <el-carousel :interval="5000">
                <el-carousel-item>
                    <img src="../assets/imgs/login/banner1.png" alt="">
                </el-carousel-item>
                <el-carousel-item>
                    <img src="../assets/imgs/login/banner3.png" alt="">
                </el-carousel-item>
                <el-carousel-item>
                    <img src="../assets/imgs/login/banner1.png" alt="">
                </el-carousel-item>
                <el-carousel-item>
                    <img src="../assets/imgs/login/banner3.png" alt="">
                </el-carousel-item>
            </el-carousel> -->
            <!-- <img src="../assets/imgs/login/banner1.png" alt="" class="banner-png"> -->
            <img src="../assets/imgs/login/newlogin.png" alt="" class="banner-png">
        </div>
    </div>
</template>

<script>
import Utils from '../../utils'
export default {
    name: "register",
    data() {
        return {
            username:"",
            password: "",
            phonenumber: "",
            verificationCode:"",
            currentregistermode: "pingtai",
            shoujiStep:1,
            verificationCodeText: "获取验证码",
            email:""
        }
    },
    methods:{
        //获取验证码
        getVerificationCode(){
            if(this.verificationCodeText == "获取验证码"){
                let count = 60;
                this.verificationCodeText = --count + "s 后重新发送";
                var interval = setInterval(()=>{
                    this.verificationCodeText = --count + "s 后重新发送";
                    if(count == 0){
                        this.verificationCodeText = "获取验证码";
                        clearInterval(interval);
                    }
                },1000)
            }
        },
        //切换登录方式
        switchregistermode(){
            if(this.currentregistermode == "shouji"){
                this.currentregistermode = "pingtai"
            }else{
                this.currentregistermode = "shouji";
                this.initData();
            }
        },
        //手机号注册下一步
        nextStepBtn(){
            //第一步，并且手机号有值
            if(this.shoujiStep == 1 && !!this.phonenumber){
                this.shoujiStep = 2;
            //第二部，手机号、验证码都有值
            }else if(this.shoujiStep == 2 && !!this.phonenumber  && !!this.verificationCode){
                this.shoujiStep = 3;
            }
        },
        // 前往登录页
        gotoLogin(){
            this.$router.push('/');
        },
        registerBtn(){
            if(this.currentregistermode == "pingtai"){
                if(this.username == ""){
                    // this.$message.warning("请输入账号");
                    return
                }
                if(this.password == ""){
                    // this.$message.warning("请输入密码")
                    return;
                }
                if(this.email==""){
                    return
                }
                let url = Config.dev_url + "/api-user/users-anon/register"
                this.$axios.post(url,{
                    username: this.username,
                    password: Utils.encrypt(this.password, 'hAw6eqnFLKxpsDv3'),
                    email:this.email
                }).then((res)=>{
                    console.log(res)
                    if(res.data.code == 0 ){
                        this.$message.success(res.data.msg);
                        if(!!this.$route.query.fromInvitation){
                            this.$router.push('/?fromInvitation=' + this.$route.query.fromInvitation );
                        }else{
                            this.$router.push('/');
                        }
                    }else{
                        // this.$message.warning(res.data.msg)
                    }
                })
            }
        },
        initData(){
            this.phonenumber = "";
            this.verificationCode = "";
            this.password = "";
            this.username = "";
            this.shoujiStep = 1;
            this.verificationCodeText = "获取验证码";
            this.currentregistermode = "pingtai";
        }
    },
    mounted(){
        this.initData();
    }
}
</script>

<style lang="less">
#register{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .register-box{
        width: 60%;
        height: 100vh;
        background: url("../assets/imgs/login/banner2.png") no-repeat;
        background-size: 100% 100%;
        position: relative;
        .register-main{
            width: 450px;
            //  height: 408px;
             height: 374px;
            background: #ffffff;
            box-shadow: 0 10px 20px 0px rgba(0,0,0,0.10);
            border-radius: 12px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            position: relative;
            overflow: hidden;
            .register-main-title{
                font-size: 22px;
                font-family: PingFangSC, PingFangSC-Semibold;
                font-weight: 600;
                text-align: left;
                color: #202328;
                line-height: 30px;
                margin-left: 40px;
                margin-top: 64px;
                margin-bottom: 32px;
            }
            .register-main-title3{
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Semibold;
                font-weight: 600;
                text-align: left;
                color: #646a74;
                line-height: 24px;
                margin-top: 49px;
                margin-left: 40px;
                margin-bottom: 16px;
            }
            .register-main-tabs{
                width: 176px;
                height: 38px;
                background: #f1f2f5;
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-left: 40px;
                padding: 2px;
                margin-bottom: 16px;
                .register-main-tab{
                    width: 88px;
                    height: 38px;
                    font-size: 14px;
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                    text-align: center;
                    color: #646a74;
                    line-height: 38px;
                    cursor: pointer;
                    border-radius: 6px;
                    box-sizing: border-box;
                    &.active{
                        background: #ffffff;
                        font-weight: 600;
                        color: #202328;
                        border: 1px solid #e9e9e9;
                        border-radius: 6px;
                        box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.10);
                    }
                }
            }
            .register-main-input{
                width: 370px;
                height: 42px;
                background: #f1f2f5 !important;
                border-radius: 6px;
                margin-left: 40px;
                margin-bottom: 16px;
                position: relative;
                .register-main-input-icon{
                    font-size: 20px;
                    position: absolute;
                    left: 11px;
                    top: 11px;
                    z-index: 10;
                    color: #646a74;
                }
                .el-input{
                    .el-input__inner{
                        width: 370px;
                        height: 42px;
                        background-color: #f1f2f5 !important;
                        border-radius: 6px;
                        border: none;
                        padding: 0px 12px 0px 40px;
                        font-size: 16px;
                        font-family: PingFangSC, PingFangSC-Regular;
                        font-weight: 400;
                        color: #202328 !important;
                        border: 2px solid #f1f2f5;
                        background-image: unset !important;
                        &::placeholder{
                            font-size: 16px;
                            font-family: PingFangSC, PingFangSC-Regular;
                            font-weight: 400;
                            color: #646a74;
                        }
                    }
                    .el-input__icon{
                        font-size: 20px;
                        color: #646a74;
                        margin-right: 5px;
                    }
                    input:-webkit-autofill , textarea:-webkit-autofill, select:-webkit-autofill {
                        -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
                        background-color: #f1f2f5 !important;//设置input框记住密码背景颜色
                        background-image: none;
                        transition: background-color 50000s ease-in-out 0s;
                        -webkit-text-fill-color: #202328 !important;//设置浏览器默认密码字体颜色
                    }
                    .el-input__inner:focus{
                        border: 2px solid var(--theme-mode-bgcolor);
                        background-color: rgba(28,116,241,0.05) !important;
                    }
                }
            }
            .register-main-input1{
                .el-input{
                    .el-input__inner{
                        padding: 0px 140px 0px 40px;
                    }
                }
                .register-main-input-tip{
                    font-size: 16px;
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                    color: var(--theme-mode-bgcolor);
                    cursor: pointer;
                    position: absolute;
                    right: 12px;
                    top: 9px;
                    line-height: 24px;
                }
                .register-main-input-tip1{
                    color: rgba(28,116,241,0.50);
                }
            }
            .register-main-button{
                width: 370px;
                height: 42px;
                background: var(--theme-mode-bgcolor);
                border-radius: 6px;
                margin-left: 40px;
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Semibold;
                font-weight: 600;
                text-align: left;
                color: #ffffff;
                text-align: center;
                line-height: 42px;
                cursor: pointer;
                &.disabled{
                    background: rgba(28,116,241,0.50);
                    cursor: not-allowed;
                }
            }
            .register-main-buttons{
                 width: 370px;
                height: 42px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-left: 40px;
                margin-bottom: 96px;
                .register-main-buttons-item1{
                    width: 142px;
                    height: 42px;
                    background: #ffffff;
                    border: 1px solid #dee0e3;
                    border-radius: 6px;
                    box-sizing: border-box;
                    font-size: 14px;
                    font-family: PingFangSC, PingFangSC-Semibold;
                    font-weight: 600;
                    text-align: center;
                    color: #202328;
                    line-height: 40px;
                    cursor: pointer;
                }
                .register-main-buttons-item2{
                    width: 212px;
                    height: 42px;
                    background: var(--theme-mode-bgcolor);
                    border-radius: 6px;
                    font-size: 16px;
                    font-family: PingFangSC, PingFangSC-Semibold;
                    font-weight: 600;
                    text-align: center;
                    color: #ffffff;
                    line-height: 40px;
                    cursor: pointer;
                }
            }
            .register-main-division{
                width: 370px;
                height: 20px;
                margin-left: 40px;
                margin-top: 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .line{
                    display: inline-block;
                    width: 135px;
                    height: 1px;
                    background: #ebebeb;
                }
                .tip{
                    font-size: 12px;
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                    color: #646a74;
                }
            }
            .register-main-wechat{
                width: 370px;
                height: 42px;
                background: #ffffff;
                border: 1px solid #dee0e3;
                border-radius: 6px;
                margin-left: 40px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 24px;
                cursor: pointer;
                margin-bottom: 96px;
                span{
                    font-size: 12px;
                    font-family: PingFangSC, PingFangSC-Semibold;
                    font-weight: 600;
                    color: #646a74;
                    margin-left: 10px;
                }
                i{
                    font-size: 24px;
                    color: #21C369;
                }
            }
            .register-main-zhuce{
                position: absolute;
                bottom: 22px;
                text-align: center;
                width: 450px; /* 要设定宽度 */
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                color: #646a74;
                display: flex;
                align-items: center;
                justify-content: center;
                span:nth-child(2){
                    color: var(--theme-mode-bgcolor);
                    cursor: pointer;
                    margin-left: 5px;
                }
                i{
                    color: var(--theme-mode-bgcolor);
                }
            }
            .register-main-wechattitle{
                font-size: 22px;
                font-family: PingFangSC, PingFangSC-Semibold;
                font-weight: 600;
                text-align: center;
                color: #202328;
                line-height: 30px;
                margin-top: 128px;
            }
            .register-main-wechatqrcode{
                width: 200px;
                height: 200px;
                margin: 0px auto;
                margin-top: 20px;
                img{
                    width: 200px;
                    height: 200px;
                }
            }
            .register-main-wechattitle1{
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                text-align: center;
                color: #646a74;
                line-height: 20px;
                margin-top: 14px;
            }
        }
    }
    .register-banner{
        width: 40%;
        height: 100vh;
        position: fixed;
        right: 0;
        top: 0;
        .banner-png{
            height: 100vh;
            width: 40vw;
        }
        .el-carousel{
            .el-carousel__container{
                height: 100vh;
                .el-carousel__item{
                    img{
                        height: 100vh;
                        width: 100%;
                    }
                }
            }
            .el-carousel__indicators{
                .el-carousel__button{
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: rgba(255,255,255,0.20);
                }
                .el-carousel__indicator.is-active{
                    .el-carousel__button{
                        background: rgba(255,255,255,0.80);
                    }
                }
            }
        }
    }
}
</style>
